var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap" },
    [
      _c(
        "div",
        { staticClass: "js-common-head" },
        [
          _c(
            "div",
            [
              _c("el-button", {
                staticClass: "back-btn",
                attrs: {
                  icon: "el-icon-arrow-left",
                  size: "small",
                  type: "primary",
                  plain: "",
                },
                on: { click: _vm.$goBack },
              }),
              _c("label", { staticClass: "label" }, [_vm._v("账户用量")]),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.search },
            },
            [_vm._v("高级搜索")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "js-common-content" },
        [
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "empty-text": "暂无数据",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "companyName", label: "企业名称" },
              }),
              _c("el-table-column", { attrs: { prop: "name", label: "账号" } }),
              _c("el-table-column", {
                attrs: { prop: "realName", label: "姓名" },
              }),
              _c("el-table-column", {
                attrs: { prop: "gmtCreated", label: "用户创建时间" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("common-pagination", {
        on: { curPageChange: _vm.handleCurPageChange },
        model: {
          value: _vm.pageOptions,
          callback: function ($$v) {
            _vm.pageOptions = $$v
          },
          expression: "pageOptions",
        },
      }),
      _c("system-usage-search", {
        on: { handleSearch: _vm.handleSearch },
        model: {
          value: _vm.searchDialogVisible,
          callback: function ($$v) {
            _vm.searchDialogVisible = $$v
          },
          expression: "searchDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }