<template>
  <el-dialog class="js-dialog-form" title="高级搜索" :visible.sync="dialogVisible" :close-on-click-modal="false" width="560px">
    <ul>
      <!-- <li class="label">账号</li> -->
      <li>
        <div class="label common-tip"><i>*</i>账号</div>
        <el-input v-model="name" placeholder="请输入名称" size="small"></el-input>
      </li>
      <!-- <li class="label">姓名</li> -->
      <li>
        <div class="label common-tip"><i>*</i>姓名</div>
        <el-input v-model="realName" placeholder="请输入创建人" size="small"></el-input>
      </li>
      <!-- <li class="label">创建时间</li> -->
      <li>
        <div class="label common-tip"><i>*</i>创建时间</div>
        <date-range v-model="dateRange"></date-range>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import DateRange from '@/components/DateRange'
export default {
  name: '',
  components:{
    DateRange
  },
  props: ['value'],
  data() {
    return {
      name: '',
      realName: '',
      dateRange: ['','']
    }
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {
  },
  methods: {
    handleCancel(){
      this.dialogVisible = false
    },
    handleConfirm(){
      let params = {
        startDate: this.dateRange[0],
        endDate: this.dateRange[1],
        name: this.name,
        realName: this.realName,
      }
      this.$emit('handleSearch',params)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>