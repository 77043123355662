<template>
  <div class="js-common-wrap">
    <div class="js-common-head">
     <div>
        <el-button class="back-btn" @click="$goBack" icon="el-icon-arrow-left" size="small" type="primary" plain></el-button>
        <label class="label">账户用量</label>
      </div>
      <el-button type="primary" size="small" @click="search">高级搜索</el-button>
    </div>
    <div class="js-common-content">
      <el-table class="js-table" :data="tableData" height="100%" empty-text="暂无数据">
        <el-table-column prop="companyName" label="企业名称"></el-table-column>
        <el-table-column prop="name" label="账号"></el-table-column>
        <el-table-column prop="realName" label="姓名"></el-table-column>
        <el-table-column prop="gmtCreated" label="用户创建时间"></el-table-column>
        <!-- <el-table-column prop="totalRtcTime" label="累计通讯用量（分钟）"></el-table-column> -->
      </el-table>
    </div>
    <common-pagination v-model="pageOptions" @curPageChange="handleCurPageChange"></common-pagination>
    <system-usage-search v-model="searchDialogVisible" @handleSearch="handleSearch"></system-usage-search>
  </div>
</template>
<script>
import CommonPagination from '@/components/CommonPagination'
import SystemUsageSearch from '@/components/SystemUsageSearch'
export default {
  name: '',
  components: {
    CommonPagination,
    SystemUsageSearch
  },
  data() {
    return {
      tableData: [],
      searchDialogVisible: false,
      pageOptions:{
        page: 1,
        perPage: 20,
        total: 0
      },
      options: {
        name: '',
        realName: '',
        startDate: '',
        endDate: ''
      }
    }
  },
  mounted() {
    this.getList(this.options)
  },
  methods: {
    getList(options){
      this.$axios({
        method: 'get',
        url: 'api/csp/mgr/v1/usage/users',
        params: {
          name: options.name,
          realName: options.realName,
          startDate: options.startDate,
          endDate: options.endDate,
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.tableData = data.data.rows
          this.pageOptions.total = data.data.total
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    // 分页
    handleCurPageChange(){
      this.getList()
    },
    search(){
      this.searchDialogVisible = true
    },
    handleSearch(params){
      this.searchDialogVisible = false
      this.getList(params)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/recognition.scss';
</style>
